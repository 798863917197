import React from 'react'
// import $ from 'jquery'
// import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'
// import {Row, Col, Accordion} from 'react-bootstrap'

import StoryOpener from '../StoryOpener/StoryOpener'
import DropCap from '../DropCap/DropCap'
import DropCapImage from '../DropCap/DropCapImage'
import StoryLede from '../StoryLede/StoryLede'
import StoryCredits from '../StoryCredits/StoryCredits'
import BodyCopy from '../BodyCopy/BodyCopy'
import EndCopy from '../EndCopy/EndCopy'
import PullQuote from '../PullQuote/PullQuote'
import PullQuoteImage from '../PullQuote/PullQuoteImage'
import RichMedia from '../RichMedia/RichMedia'
// import StaticMedia from '../StaticMedia/StaticMedia'
import VideoMedia from '../VideoMedia/VideoMedia'
import MaskedMedia from '../MaskedMedia/MaskedMedia'

// import { InView } from 'react-intersection-observer'



const WidgetContainer = (props) => {


if (props.widgetType) {
    return (

      <>

      {
        ((props.widgetType==='widget_storyopener'))?(
          <StoryOpener
                        isMobile={props.isMobile} isSafari={props.isSafari} enviOS={props.enviOS}
                        articleObject={props.articleObject}
                        widgetObject={props.widgetObject} />
        ):("")
      }

      {
        ((props.widgetType==='widget_dropcap'))?(
          <DropCap isMobile={props.isMobile} isSafari={props.isSafari}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }

      {
        ((props.widgetType==='widget_dropcapimage'))?(
          <DropCapImage isMobile={props.isMobile} isSafari={props.isSafari}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }


      {
        ((props.widgetType==='widget_storylede'))?(
          <StoryLede isMobile={props.isMobile} isSafari={props.isSafari}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }

      {
        ((props.widgetType==='widget_storycredits'))?(
          <StoryCredits isMobile={props.isMobile} isSafari={props.isSafari}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }
      {
        ((props.widgetType==='widget_bodycopy'))?(
          <BodyCopy isMobile={props.isMobile} isSafari={props.isSafari}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }
      {
        ((props.widgetType==='widget_pullquote'))?(
          <PullQuote isMobile={props.isMobile} isSafari={props.isSafari}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }
      {
        ((props.widgetType==='widget_pullquoteimage'))?(
          <PullQuoteImage isMobile={props.isMobile} isSafari={props.isSafari}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }
      {
        ((props.widgetType==='widget_richmedia'))?(
          <RichMedia isMobile={props.isMobile} isSafari={props.isSafari} enviOS={props.enviOS}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }

      {
        ((props.widgetType==='widget_videomedia'))?(
          <VideoMedia isMobile={props.isMobile} isSafari={props.isSafari}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }
      {
        ((props.widgetType==='widget_maskedmedia'))?(
          <MaskedMedia isMobile={props.isMobile} isSafari={props.isSafari} enviOS={props.enviOS} 
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }
      {
        ((props.widgetType==='widget_endcopy'))?(
          <EndCopy isMobile={props.isMobile} isSafari={props.isSafari}
              articleObject={props.articleObject}
              widgetObject={props.widgetObject} />
        ):("")
      }

    </>

    )

} else {
  return ("")
}



}

export default WidgetContainer
