import React from 'react'
// import $ from 'jquery'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'

// import testImage1 from '../../_assets/img/test/wanda-2.png'

const PullQuoteImage = (props) => {



  if (props && !props.isMobile) {

      return (
        <div className="width_ctrl">
        <div className="pullquote__container d-flex no-gutters position-relative">
        <Controller loglevel="0">
          <Scene duration={'100%'} triggerHook=".8"  >

            <Timeline>

              <div className={"pull__container pullquote__inner d-flex align-items-center col-12 col-md-4 align-items-start justify-content-center "+ props.widgetObject.position + ((props.widgetObject.full_height)?(" pullquote__height_ctrl"):(""))} >
                <Tween
                  to={((props.widgetObject.stretch)?({
                    transform:"scale(1,2.5)",
                    transformOrigin:"bottom"
                  }):(""))}
                  delay={0}
                  >

                    <img alt="Queue" src={props.widgetObject.source} className="w-100 pullquoteimage"/>

                </Tween>

              </div>
              </Timeline>
              

        </Scene>

      </Controller>
      </div>
      </div>

      )
    } else if (props && props.isMobile) {
      return (
        <div className="width_ctrl">
        <div className="pullquote__container d-flex no-gutters position-relative">


              <div className={"pull__container pullquote__inner d-flex align-items-center col-12 col-md-4 align-items-start justify-content-center "+ props.widgetObject.position + ((props.widgetObject.full_height)?(" pullquote__height_ctrl"):(""))} >


                    <img alt="Queue" src={props.widgetObject.source} className="w-100 pullquoteimage"/>



              </div>

      </div>
      </div>
      )
    } else {
      return ("")
    }


}

export default PullQuoteImage
