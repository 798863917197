import React, {useLayoutEffect} from 'react'
import $ from 'jquery'
// import { useHistory } from "react-router-dom";
// import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'

// import testObjects from '../../Common/TestContentObjects/NavObjects'



const Credits = (props) => {


  const iconInst = '/_assets/img/test/credits/icon-inst.png'
  const iconTwitter = '/_assets/img/test/credits/icon-twitter.png'
  const iconClose = '/_assets/img/test/credits/icon-close.png'



  useLayoutEffect( () => {
    let subscribed = true;
    if (subscribed) {

      $(document).on('click', '.credits__close', (e)=> {
        e.preventDefault()
        // console.log('clicked');
        $('.credits__container').removeClass('active')
      })

    }
    return ()=>{subscribed=false}
  }, [ ])

    return (

          <div className="credits__container  d-flex flex-column">
            <div className="credits__close d-flex align-items-center">
              <img src={iconClose} className="credits__close--image" alt="Queue" />
            </div>
            <div className="credits__content pt-5 d-flex align-items-center flex-column justify-content-center">
              <div className="my-3 font-bold credits__title text-center w-100">
                Queue Issue 3:<br/>The Comedy Issue
              </div>
              <div className="my-5 d-flex align-items-center flex-wrap justify-content-center">
                <a href="https://instagram.com/netflixqueue" target="_blank" rel="noopener noreferrer"><img src={iconInst} className="credits__icon mx-2 d-inline-block" alt="Queue" /></a>
                <a href="https://twitter.com/netflixqueue" target="_blank" rel="noopener noreferrer"><img src={iconTwitter} className="credits__icon mx-2 d-inline-block" alt="Queue" /></a>
                <span className="credits__handle d-block w-100 text-center mt-3">@NetflixQueue</span>
              </div>
              <div className="credits__credit mt-2">
                <span className="credits__credit--by d-block w-100 text-center">Cover artwork by</span> <span className="credits__credit--name d-block w-100 text-center">Shyama Golden</span>
              </div>
              <div className="credits__credit d-none">
                <span className="credits__credit--by d-block w-100 text-center">Around the world animation by</span> <span className="credits__credit--name d-block w-100 text-center">Mat Maitland</span>
              </div>
              <div className="credits___footer--container d-flex w-100 p-4 mt-auto ">
                <div className="credits___footer d-flex flex-column flex-md-row">
                  <span className="">Presented by <a href="https://netflix.com" target="_blank" rel="noopener noreferrer">Netflix</a></span>
                  <span className="pl-0 pl-md-4">Designed by <a href="https://www.pentagram.com" target="_blank" rel="noopener noreferrer">Pentagram</a> </span>
                  <span className="pl-0 pl-md-4">Originally published May 2020</span>
                  <span className="pl-0 pl-md-4 loading">
                    <a className="" href="https://drive.google.com/file/d/1E23TxnAODGsJrgjUmmB9dO6hz8wUVefV/view" target="_blank" rel="noopener noreferrer">Privacy &amp; Terms</a>
                    </span>
                </div>
                <div className="credits___copyright align-self-end ml-auto">
                  <div className="credits___copyright--inner">&copy; Netflix 2020</div>
                </div>
              </div>
            </div>
          </div>



    )
}

export default Credits
