import React, { useState } from 'react'
// import $ from 'jquery'
// import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'




const StoryCredits = (props) => {

    // console.log($('#root').height(), document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight)

    const [linkIsCopied, setLinkIsCopied] = useState(false)


    const copyLink = '/_assets/img/test/ShareIconAsset10.svg'
    const linkCopied = '/_assets/img/test/SuccessfullySharedIconAsset11.svg'

    // const storyCreditDesktopStyle = {
    //   right:0,
    //   top:0,
    //   height:'100vh',
    //   position:'absolute',
    //   alignItems:'flex-start'
    // };
    //
    // const storyCreditMobileStyle = {
    //
    // };

    return (
      <div className="widget_storycredits width_ctrl">
      <div className="storycredits__container  d-flex no-gutters position-relative mt-5 mt-md-0">
        <div className={"pull__container d-flex flex-column no-gutters col-12 col-md-4 py-4 py-md-0 align-items-center justify-content-center "} >
          <div className="text-center pt-3">

          {props.widgetObject.credits.map((obj,i)=>{
            return (
              <div key={i}>
              <div className="storycredits__title loading">{obj.title}</div>
              <div className="storycredits__name loading" dangerouslySetInnerHTML={{__html: obj.name}} />
              </div>
            )
          })}

          </div>

          <div className="text-center mb-4 mb-md-0">
            <a href="/#" className={" "+((!linkIsCopied)?(""):("copylink__button--copied"))} onClick={(e)=>{
              e.preventDefault();
              setLinkIsCopied(true);
              navigator.clipboard.writeText("https://www.comedyissue.netflixqueue.com/"+props.articleObject.slug)
            }}>
            <img alt="Queue" src={(!linkIsCopied)?(copyLink):(linkCopied)} className="copylink__button"  />
            </a>
            <div className="loading copylink__text font-bold mt-2">{(!linkIsCopied)?("Share"):("Link Copied")}</div>
          </div>
        </div>
      </div>
      </div>
    )
}

export default StoryCredits
