import ReactGA from "react-ga";

export const initGA = (trackingID) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
    ReactGA.initialize(trackingID, {debug: false});
  }
}

export const gaPageView = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};
