const _GET_headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
}

const token_uri = '/api/auth/'

export async function get(uri, success = null, fail = null) {
    await fetch(uri, { headers: _GET_headers })
        .then( response => {
            if(response.status !== 200) {
                if(fail !== null) fail(response.status)
            }
            return response.json()
        })
        .then( response => {
            if (!response.error) {
                if (success !== null) success(response)
            } else {
                if (fail !== null) fail(response.message)
            }
        })
        .catch( error => {
            console.error(error)
        } )
}

export async function upload(uri, form = null, success = null, fail = null) {
    await get('/api/auth/', (auth) => {
        fetch(uri, {
            method: 'POST',
            headers: {
                'CSRF-Token': auth.csrfToken
            },
            body: form
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (!response.error) {
                    if (success !== null) success(response)
                } else {
                    if (fail !== null) fail(response.message)
                }
            })
            .catch(error => console.error(error))
    })
};

export async function post(uri, body = null, success = null, fail = null) {
    await get('/api/auth/', (auth) => {
        fetch(uri, {
            method: 'POST',
            headers: {
                'Content-Type': "application/json",
                'CSRF-Token': auth.csrfToken
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (!response.error) {
                    if (success !== null) success(response)
                } else {
                    if (fail !== null) fail(response.message)
                }
            })
            .catch(error => console.error(error))
    })
};

export async function put(uri, body, success = null, fail = null) {
    await get(token_uri, (auth) => {
        fetch(uri, {
            method: 'PUT',
            headers: {
                'Content-Type': "application/json",
                'CSRF-Token': auth.csrfToken
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (!response.error) {
                    if (success !== null) success(response)
                } else {
                    if (fail !== null) fail(response.message)
                }
            })
            .catch(error => console.error(error))
    })
}

export async function del(uri, success = null, fail = null) {
    await get(token_uri, (auth) => {
        fetch(uri, {
            method: 'DELETE',
            headers: {
                'Content-Type': "application/json",
                'CSRF-Token': auth.csrfToken
            },
        })
            .then( response => {
                return response.json()
            })
            .then( response => {
                if (!response.error) {
                    if (success !== null) success(response)
                } else {
                    if (fail !== null) fail(response.message)
                }
            })
            .catch( error => console.error(error) )
    })
}