import React, {Fragment, useLayoutEffect} from 'react'
// import $ from 'jquery'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

// import StoryLede from '../../../Common/Widgets/StoryLede/StoryLede'

// import {Row, Col, Accordion} from 'react-bootstrap'

// import letterman1 from '../../_assets/img/test/letterman-1.jpg'

// import lettermanOpener2 from '../../_assets/img/test/letterman-opener-2.svg'
// import lettermanOpener4 from '../../_assets/img/test/letterman-opener-4.svg'
// import lettermanOpenerPickle from '../../_assets/img/test/letterman-opener-pickle.svg'
// import lettermanOpener7 from '../../_assets/img/test/letterman-opener-7.svg'
// import lettermanOpenerLetterman from '../../_assets/img/test/letterman-opener-letterman.svg'

// import wandaWFill from '../../_assets/img/test/wandaw-fill.svg'
// import wandaWOutline from '../../_assets/img/test/wandaw-outline.svg'



const StoryOpener = (props) => {

  const imageLoadHandler = (e) => {
    // console.log(e.target)
    e.target.style.opacity = 1
  }

  // const controllerRef = useRef()

  // const [ ((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop)), set((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop)) ] = useState({}),
        // [ ((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)), set((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)) ] = useState({}),
        // [ lede, setLede ] = useState(null)
        //
        // const enteredScene = () => {
        //   console.log('scene')
        // }

  useLayoutEffect( () => {
    let subscribed = true;
    if (subscribed) {


      // set((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop))((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop))

      // set((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop))((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop))
      // console.log(controllerRef, controllerRef.current)
      // console.log('props.widgetObject.background',props.articleObject.slug, props.widgetObject.background)
      //
      // if (("lede" in props.widgetObject)) {
      //   setLede(props.widgetObject.lede)
      // }
    }
    return ()=>{subscribed=false}
  }, [])


  // const storyLedeStyle = {
  //   backgroundColor:props.widgetObject.lede_bg_color
  // }

  if (props.widgetObject && ((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop)) && ((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop))) {


    return (

      <div className="" style={{height:"100%",backgroundColor:((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop)).backgroundColor}}>
        <Controller loglevel="2" refreshInterval="1">
          <Scene duration={props.widgetObject.duration} pin triggerHook="onLeave">
            <Timeline>
              <div id="" className="storyopener__container animreset__top">


                  {(("video" in ((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop))))?(
                    <div id="" className="storyopener__bg-video">
                      <video autoPlay loop muted playsInline className={"storyopener__bg-video-element "+((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop)).video.align}>
                          <source src={((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop)).video.source} type="video/mp4" />
                          Your browser doesn't support embedded videos.
                      </video>
                    </div>
                  ):(
                    (((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop)) && ("image" in ((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop))))?(
                    <div id="" className="storyopener__bg-image " style={((props.isMobile)?(props.widgetObject.background.mobile):(props.widgetObject.background.desktop)).image.style} />
                    ):("")
                  )}


                  <div className={"storyopener__headlinecontainer " + ((props.enviOS)?("ios--container "):(" ")) + ((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)).containerClassName} style={((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)).containerStyle}>


                    {(("tweens" in ((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop))))?(

                      <Timeline
                        target={
                          <Fragment>
                          {((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)).media.map((obj,i)=>{
                            return (
                              <div key={i} className={"storyopener__headlineelement "+obj.className} style={obj.style}><img onLoad={(e)=>{imageLoadHandler(e)}} key={i} alt="Queue" src={obj.s3} /></div>
                            )
                          })}
                          </Fragment>
                        }>

                      {((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)).tweens.map((obj,i)=>{
                        return (
                          <Tween key={i} {...obj} force3D="true" rotationZ="0.01" />
                          )
                        })}



                        {(("mask" in ((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop))))?(
                          <div className="storyopener__maskcontainer">
                        <Timeline
                          target={
                            <div className="storyopener__scalecontainer" style={((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)).mask.image.style}>
                                <img alt="Queue" src={((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)).mask.image.source} className={" "+((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)).mask.image.className}   />
                            </div>
                          }>
                          {((props.isMobile)?(props.widgetObject.headline.mobile):(props.widgetObject.headline.desktop)).mask.tweens.map((obj,i)=>{
                            return (
                              <Tween key={i} {...obj} />
                              )
                            })}
                        </Timeline>
                        </div>
                        ):("")}


                      </Timeline>
                    ):("")}

                    </div>








                  {(("lede" in props.widgetObject))?(
                    <div>
                    {(Object.keys(props.widgetObject.lede).length)?(
                  <Tween to={{ opacity: 1, top:"-100%" }} duration="10" ease="none" >
                      <div className="storyopener__storylede__container position-absolute w-100  pb-5 d-flex no-gutters align-items-start justify-content-start">
                        <div className="width_ctrl ">
                          <div className="storylede__textwrapper no-gutters">
                            <div className="storylede__text-inner text-left" style={{width:((props.isMobile)?(props.widgetObject.lede.mobile.style.width):(props.widgetObject.lede.desktop.style.width))}} >
                              <div className="loading storylede__text-leftpadding" style={{backgroundColor:((props.isMobile)?(props.widgetObject.lede.mobile.style.backgroundColor):(props.widgetObject.lede.desktop.style.backgroundColor))}} />
                                <span className="storylede__text loading" style={((props.isMobile)?(props.widgetObject.lede.mobile.style):(props.widgetObject.lede.desktop.style))}dangerouslySetInnerHTML={{__html: props.widgetObject.lede.content}} />
                            </div>
                          </div>
                        </div>
                      </div>

                      </Tween>

                    ):("")}
                    </div>
                  ):("")}






              </div>
            </Timeline>
          </Scene>
        </Controller>
      </div>

    )

  } else {
    return ("")
  }

}

export default StoryOpener
