import React from 'react'
// import $ from 'jquery'
// import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'

// import testImage1 from '../../_assets/img/test/wanda-2.png'

const StoryLede = (props) => {


    return (
      <div className="widget_storylede storylede__container pb-5 d-flex no-gutters align-items-end justify-content-start">
        <div className="width_ctrl bodycopy__container">
          <div className="storybody__storylede__textwrapper storylede__textwrapper no-gutters">
            <div className="storylede__text-inner col-12 col-md-8 text-left">
              <div className="loading storylede__text-leftpadding" style={{
                backgroundColor:props.widgetObject.lede_bg_color
              }} />
                <span className="loading storylede__text" style={{
                  backgroundColor:props.widgetObject.lede_bg_color,
                  color:((props.widgetObject.lede_text_color)?(props.widgetObject.lede_text_color):("#fff")),
                  fontWeight:((props.widgetObject.lede_font_weight)?(props.widgetObject.lede_font_weight):("400"))
                }} dangerouslySetInnerHTML={{__html: props.widgetObject.lede_content}} />
            </div>
          </div>
        </div>
      </div>
    )
}

export default StoryLede
