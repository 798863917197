import React, {useRef, useState} from 'react'
// import $ from 'jquery'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'

// import testImage1 from '../../_assets/img/test/wanda-2.png'

const PullQuote = (props) => {

    const [playingAudio, setPlayingAudio] = useState(false)

    const audioRef = useRef()
    // let playingAudio = true;


    const playAudio = () => {
      audioRef.current.play()
      setPlayingAudio(true)
    }
    const stopAudio = () => {
      audioRef.current.pause()
      setPlayingAudio(false)
    }
    const audioEnded = () => {
      setPlayingAudio(false)
    }

    // useLayoutEffect( () => {
    //   let subscribed = true;
    //   if (subscribed) {
    //   }
    //   return ()=>{subscribed=false}
    // }, [props])

    // const pullQuoteDesktopStyle = {
    //
    // };
    //
    // const pullQuoteMobileStyle = {
    //
    // };

    if (props.widgetObject.is_audio) {

      return (
        <div className=" pullquote__audio--container position-relative width_ctrl ">
        <div className="   pullquote__container d-flex no-gutters position-relative">
              <div className={"pull__container pullquote__inner col-12 col-md-4 align-items-start justify-content-center "+ props.widgetObject.position} >
                  <div className={"d-flex flex-column justify-content-center "+((props.widgetObject.full_height)?("pullquote__height_ctrl"):(""))}>
                    <div className="pullquote__text text-center" style={{color:props.widgetObject.text_color}}>
                      <img alt="Queue" src={props.widgetObject.eq_image_s3} className="pullquote__eq--image"/>
                      <audio ref={audioRef} src={props.widgetObject.audio_s3} onEnded={()=>{audioEnded()}} style={{display:"none"}}/>
                    </div>
                    <div className=" text-center" style={{color:props.widgetObject.text_color}}>
                      <a href="/#" onClick={(e)=>{
                        e.preventDefault();
                        if (playingAudio) {
                          stopAudio()
                        } else {
                          playAudio()
                        }
                      }}>

                      {((!playingAudio)?(
                        <img alt="Queue" src={props.widgetObject.play_image_s3} className="pullquote__audio--button"/>
                      ):(
                        <img alt="Queue" src={props.widgetObject.pause_image_s3} className="pullquote__audio--button"/>
                      ))}

                      </a>
                    </div>
                    <div className="loading  text-center pullquote__instruction px-4" style={{color:props.widgetObject.text_color}}>{props.widgetObject.instructions}</div>
                  </div>
              </div>
      </div>
      </div>
      )

    } else if (props.widgetObject.stretch) {

      return (
        <div className="width_ctrl">
        <div className="pullquote__container d-flex no-gutters position-relative">
        <Controller loglevel="0">
          <Scene duration={'100%'} triggerHook=".5"  >

            <Timeline>

              <div className={"pull__container pullquote__inner d-flex align-items-center col-12 col-md-4 align-items-start justify-content-center "+ props.widgetObject.position + ((props.widgetObject.full_height)?(" pullquote__height_ctrl"):(""))} >
                <Tween
                  from={{
                    opacity: 1
                  }}
                  to={((props.widgetObject.stretch)?({
                    transform:"scale(1,2.5)",
                    transformOrigin:"bottom"
                  }):(""))}
                  delay={0}
                  >
                  <div className="d-flex flex-column justify-content-center">
                    <div className="text-center">
                      <img alt="Queue" src={props.widgetObject.openQuote_s3} className="pullquote__quotation pullquote__quotation--open"/>
                    </div>

                    <div className="loading  pullquote__text my-4 text-center" style={{color:props.widgetObject.text_color,fontSize:props.widgetObject.font_size,fontWeight:props.widgetObject.font_weight}} dangerouslySetInnerHTML={{__html: props.widgetObject.text}} />


                    {(("attribution" in props.widgetObject))?(
                      <div className="loading text-center mb-4 pullquote__attribution px-5" style={{color:props.widgetObject.text_color}} dangerouslySetInnerHTML={{__html: props.widgetObject.attribution}} />
                    ):("")}

                    <div className="text-center">
                      <img alt="Queue" src={props.widgetObject.closeQuote_s3} className="pullquote__quotation pullquote__quotation--close"/>
                    </div>
                  </div>
                </Tween>

              </div>
              </Timeline>

        </Scene>
      </Controller>
      </div>
      </div>

      )
    } else {
      return (
        <div className="width_ctrl">
        <div className="pullquote__container d-flex no-gutters position-relative">

              <div className={"pull__container pullquote__inner d-flex align-items-center col-12 col-md-4 align-items-start justify-content-center "+ props.widgetObject.position + ((props.widgetObject.full_height)?(" pullquote__height_ctrl"):(""))}  >

                  <div className="d-flex flex-column justify-content-center">
                    <div className="text-center">
                      <img alt="Queue" src={props.widgetObject.openQuote_s3} className="pullquote__quotation pullquote__quotation--open"/>
                    </div>

                    <div className="loading pullquote__text my-4 text-center" style={{color:props.widgetObject.text_color,fontSize:props.widgetObject.font_size,fontWeight:props.widgetObject.font_weight}} dangerouslySetInnerHTML={{__html: props.widgetObject.text}} />


                    {(("attribution" in props.widgetObject))?(
                      <div className="loading text-center mb-4 pullquote__attribution px-5" style={{color:props.widgetObject.text_color}} dangerouslySetInnerHTML={{__html: props.widgetObject.attribution}} />
                    ):("")}

                    <div className="text-center">
                      <img alt="Queue" src={props.widgetObject.closeQuote_s3} className="pullquote__quotation pullquote__quotation--close"/>
                    </div>
                  </div>


              </div>

      </div>
      </div>

      )
    }


}

export default PullQuote
