import React, { useState, useLayoutEffect } from 'react'
import { isMobileOnly,isSafari, isIOS } from 'react-device-detect'
 // import { Controller, Scene } from 'react-scrollmagic'
 import $ from 'jquery'
 import {initGA, gaPageView} from './Common/Helpers/Tracking'
import { BrowserRouter as Router } from 'react-router-dom'
import AppProvider from './Common/Helpers/Context'
import { useHistory } from "react-router-dom";
// import navObjects from './Common/TestContentObjects/NavObjects'
import ArticleList from './Components/ArticleList/ArticleList'
import TableOfContentsInner from './Components/TableOfContents/TableOfContentsInner'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Common/_assets/styles/main.scss'

  //
  // const queueLogoRed = process.env.PUBLIC_URL+'/_assets/img/logo-queue-red.svg'
  // const queueLogoWhite = process.env.PUBLIC_URL+'/_assets/img/logo-queue-white.svg'
  const loadImage = process.env.PUBLIC_URL+'/_assets/img/test/Q_Load_Low.gif'
  const bg = process.env.PUBLIC_URL+'/_assets/img/test/coverbg.jpg'
  const bgmobile = process.env.PUBLIC_URL+'/_assets/img/test/coverbg-mobile.jpg'

function App(props) {

  const [isMobile, setIsMobile] = useState(false)
  const [browserSafari, setBrowserSafari] = useState(false)
  const [enviOS, setEnviOS] = useState(false)
  const history = useHistory()




  useLayoutEffect(() => {
    let subscribed=true
    if (subscribed) {

      // console.log('ga')
        initGA('UA-167055299-2')
        gaPageView()

      setIsMobile(isMobileOnly);
      setBrowserSafari(isSafari);
      setEnviOS(isIOS);


      $(document).on('touchend click', '.toc__logo--mouseover, .storyfooter__logo-container', (e) => {
          e.preventDefault()
            $('.tocflyoutnav__container').addClass('active')
      });
      $(document).on('touchend click', '.tocnav__backarrow, .tocnav__logo--image', (e) => {
          e.preventDefault()
            $('.tocflyoutnav__container').removeClass('active')
      });

    }
  }, [history])




  // <TableOfContents isMobile={props.isMobile} isSafari={props.isSafari} />


  return (

    <div className="w-100 h-100">

<div id="loaderContainer" className={((props.enviOS)?("ios--container"):(" "))}><img src={loadImage} className="loader--image" alt="Queue" /></div>
<div className="cover__container--bg " style={{backgroundImage:((isMobile)?("url("+bgmobile+")"):("url("+bg+")"))}} />

    <Router id="root" className="">


      <AppProvider>
        <ArticleList isMobile={isMobile} isSafari={browserSafari} enviOS={enviOS} {...props}/>
        <TableOfContentsInner isMobile={isMobile} isSafari={browserSafari} {...props}/>
      </AppProvider>

    </Router>



</div>

  )
}

export default App;
