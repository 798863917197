import React, {useLayoutEffect, useState, useRef} from 'react'
import $ from 'jquery'
// import { useHistory } from "react-router-dom";

// import {Img} from 'react-image'

import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'
import { Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'
// import Credits from './Credits'

import ReactCursorPosition, { INTERACTIONS } from 'react-cursor-position'
import testObjects from '../../Common/TestContentObjects/NavObjects'


// console.log(process.env.PUBLIC_URL)

const eyesBase = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/eyes-base.png'
const blink1 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/blink-1.png'
const blink2 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/blink-2.png'
const blink3 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/blink-3.png'
const blink4 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/blink-4.png'
const blink5 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/blink-5.png'
const blink6 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/blink-6.png'
const blink7 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/blink-7.png'
const blink8 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/blink-8.png'
const blink9 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/blink-9.png'


const eyesClosed = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/eyes-closed.png'
const eyesDown = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/eyes-down.png'
const eyesLeft1 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/eyes-left1.png'
const eyesLeft2 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/eyes-left2.png'
const eyesLeft3 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/eyes-left3.png'
const eyesRight1 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/eyes-right1.png'
const eyesRight2 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/eyes-right2.png'
const eyesRight3 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/eyes-right3.png'

// const bg = process.env.PUBLIC_URL+'/_assets/img/test/coverbg.jpg'
const fly1 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/fly_cursor_200px.gif'

// const logodesktop = process.env.PUBLIC_URL+'/_assets/img/test/logo-comedy-issue.png'
// const logomobile = process.env.PUBLIC_URL+'/_assets/img/test/logo-comedy-issue-mobile.png'



// const q0 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q0.png'
// const q1 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q1.png'
// const q2 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q2.png'
// const q3 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q3.png'
// const q4 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q4.png'
// const q5 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q5.png'
// const q6 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q6.png'
// const q7 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q7.png'
// const q8 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q8.png'
// const q9 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q9.png'
// const q10 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q10.png'
// const q11 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q11.png'
// const q12 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q12.png'
// const q13 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q13.png'
// const q14 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q14.png'
// const q15 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q15.png'
// const q16 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q16.png'
// const q17 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q17.png'
// const q18 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q18.png'
// const q19 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q19.png'
// const q20 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q20.png'
// const q21 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q21.png'
// const q22 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q22.png'
// const q23 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q23.png'
// const q24 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q24.png'
// const q25 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q25.png'
// const q26 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q26.png'
// const q27 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q27.png'
// const q28 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q28.png'
// const q29 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q29.png'
// const q30 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q30.png'
// const q31 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q31.png'
// const q32 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q32.png'
// const q33 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q33.png'
// const q34 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q34.png'
// const q35 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q35.png'
// const q36 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q36.png'
// const q37 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q37.png'
// const q38 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q38.png'
// const q39 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q39.png'
// const q40 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q40.png'
// const q41 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q41.png'
// const q42 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q42.png'
// const q43 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q43.png'
// const q44 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q44.png'
// const q45 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q45.png'
// const q46 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q46.png'
const q47 = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/q47.png'

const comedyIssueHeadlineDesktop = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/TheComedyIssue_Horizontal.svg'
const comedyIssueHeadlineMobile = process.env.PUBLIC_URL+'/_assets/img/test/cover-comedy/TheComedyIssue_Stacked.svg'

const scrollArrowDown = process.env.PUBLIC_URL+'/_assets/img/Arrow_Down_WhiteAsset.svg'


// const loadImage = '/_assets/img/test/Q_Load_Low.gif'

const TableOfContentsInner = (props) => {

  // const imageLoaded = (img) => {
  //   console.log(img,'coverImageLoaded')
  //   setCoverLoaded(true);
  // }




  const [componentLoaded, setComponentLoaded] = useState(false)
  const flyRef = useRef()
  // const cursorContainer = useRef()

  // window.onload = () => {
  //   $("#cover").on('mousemove', (e) => {
  //     //e.preventDefault();
  //     var xPos = e.clientX;
  //     var yPos = e.clientY;
  //
  //     $('#fly').css({
  //       left:xPos,
  //       top:yPos
  //     });
  //
  //     var winCenterX = $(window).width()/2;
  //     var winLeftEdge1 = winCenterX-50;
  //     var winLeftEdge2 = winCenterX-100;
  //     var winLeftEdge3 = winCenterX-150;
  //     var winRightEdge1 = winCenterX+50;
  //     var winRightEdge2 = winCenterX+100;
  //     var winRightEdge3 = winCenterX+150;
  //     var imgBottom = $('#eyesBase').offset().top + $('#eyesBase').height() - 100;
  //     //console.log('X:'+xPos, 'Y:'+yPos, 'image offset: '+imgBottom);
  //     $('#eyesDown, #eyesLeft1, #eyesLeft2, #eyesLeft3, #eyesRight1, #eyesRight2, #eyesRight3').removeClass('active');
  //
  //     if (yPos > imgBottom) {
  //       // console.log('bottom');
  //       $('#eyesDown').addClass('active');
  //     } else if ((xPos < winLeftEdge1) && (xPos > winLeftEdge2)) {
  //       // console.log('left 1');
  //       $('#eyesLeft1').addClass('active');
  //     } else if ((xPos < winLeftEdge2) && (xPos > winLeftEdge3)) {
  //       // console.log('left 2');
  //       $('#eyesLeft2').addClass('active');
  //     } else if ((xPos < winLeftEdge3)) {
  //       // console.log('left 3');
  //       $('#eyesLeft3').addClass('active');
  //     } else if ((xPos > winRightEdge1) && (xPos < winRightEdge2)) {
  //       // console.log('right 1');
  //       $('#eyesRight1').addClass('active');
  //     } else if ((xPos > winRightEdge2) && (xPos < winRightEdge3)) {
  //       // console.log('right 2');
  //       $('#eyesRight2').addClass('active');
  //     } else if (xPos > winRightEdge3) {
  //       // console.log('right 3');
  //       $('#eyesRight3').addClass('active');
  //     } else if ((xPos > winLeftEdge1) && (xPos < winRightEdge1)) {
  //       // console.log('center');
  //     }
  //
  //   })
  // }

  const mouseFollow = (props) => {
    // console.log(props.position.x, props.position.y)
    flyRef.current.style.left = props.position.x+"px"
    flyRef.current.style.top = props.position.y+"px"
    // flyRef.current.style.opacity = (!props.isPositionOutside)?(.5):(1)

    let xPos = props.position.x
    let yPos = props.position.y

    let winCenterX = $(window).width()/2;
    let win10 = $(window).width()/10;
    let win5 = $(window).width()/20;
    let winLeftEdge1 = winCenterX-(win10);
    let winLeftEdge2 = winCenterX-(win10+win5);
    let winLeftEdge3 = winCenterX-(win10+win10);
    let winRightEdge1 = winCenterX+(win10);
    let winRightEdge2 = winCenterX+(win10+win5);
    let winRightEdge3 = winCenterX+(win10+win10);
    let imgBottom = $('#eyesBase').offset().top + $('#eyesBase').height() - 100;
    //console.log('X:'+xPos, 'Y:'+yPos, 'image offset: '+imgBottom);
    $('#eyesClosed, #eyesDown, #eyesLeft1, #eyesLeft2, #eyesLeft3, #eyesRight1, #eyesRight2, #eyesRight3').removeClass('active');

    if (yPos > imgBottom) {
          // console.log('bottom');
      $('#eyesDown').addClass('active');
    } else if ((xPos < winLeftEdge1) && (xPos > winLeftEdge2)) {
      // console.log('left 1');
      $('#eyesLeft1').addClass('active');
    } else if ((xPos < winLeftEdge2) && (xPos > winLeftEdge3)) {
      // console.log('left 2');
      $('#eyesLeft2').addClass('active');
    } else if ((xPos < winLeftEdge3)) {
      // console.log('left 3');
      $('#eyesLeft3').addClass('active');
    } else if ((xPos > winRightEdge1) && (xPos < winRightEdge2)) {
      // console.log('right 1');
      $('#eyesRight1').addClass('active');
    } else if ((xPos > winRightEdge2) && (xPos < winRightEdge3)) {
      // console.log('right 2');
      $('#eyesRight2').addClass('active');
    } else if (xPos > winRightEdge3) {
      // console.log('right 3');
      $('#eyesRight3').addClass('active');
    } else if ((xPos > winLeftEdge1) && (xPos < winRightEdge1)) {
      // $('#eyesClosed').addClass('active');
    }

  }


  useLayoutEffect( () => {
    let subscribed = true;
    if (subscribed) {

      setComponentLoaded(true)

      // BLINK
      const blinkImage = () => {
        // console.log('close');
        var blinkImages = document.querySelectorAll('.coverimage__blink');
        var random = Math.floor(Math.random()*($(blinkImages).length));
        var blinkImage = $(blinkImages).eq(random);
        $(blinkImage).addClass('active');
        setTimeout(()=>{
          $(blinkImage).removeClass('active');
        }, 150);
      }
      const blinkTimer = () => {
        var min = 1,
          max = 3;
        var rand = Math.floor(Math.random() * (max - min + 1) + min); //Generate Random number between 5 - 10
        // console.log('Wait for ' + rand + ' seconds');
        blinkImage();
        setTimeout(blinkTimer, rand * 500);
      }
      blinkTimer();


    }
    return ()=>{subscribed=false}
  }, [ ])




  const windowLoadHandler = () => {
    // console.log(window.location.pathname)
    if (window.location.pathname !== "/") {
      let pathname = window.location.pathname.replace("/","")
      let allArticleNames = []
      let articleNames = testObjects.testObjects
      for (let i = 0; i < articleNames.length; i++) {
          let articleName = articleNames[i];
          allArticleNames.push(articleName.slug)
      }
      // console.log(allArticleNames)
      if (allArticleNames.includes(pathname)) {
        document.getElementById(pathname).scrollIntoView()
      }
    }
  }


  const imageLoadHandler = (e) => {
    // console.log(e.target)
    e.target.style.opacity = 1

    // let qs = document.querySelectorAll('.qanimation__image')
    // for (let i = 0; i < qs.length; i++) {
    //     let q = qs[i];
    //     q.style.opacity = 1
    // }



    setTimeout(()=>{
      $('.coverimage__container').addClass('coverimage__container--loaded')

      windowLoadHandler()
      $('#loaderContainer').addClass('loaded')

      setTimeout(()=>{
        $('#loaderContainer').remove()
      }, 500);

    }, 1500);
    // setTimeout(()=>{
    //     let blinks = document.querySelectorAll('.coverimage__blink')
    //     for (let i = 0; i < blinks.length; i++) {
    //         let blink = blinks[i];
    //         blink.style.opacity = 0
    //     }
    //   }, 750);

  }


  // <img id="eyesBase" className="coverimage__image" src={eyesBase} alt="Queue"/>

  if (componentLoaded) {

    return (


<div id="cover" >
<div ref={flyRef} id="fly"><img id="flyimage" className="flyimageclass" src={fly1} alt="Queue" /></div>
<ReactCursorPosition
        className="cursorContainer"
        shouldDecorateChildren={false}
        activationInteractionMouse={INTERACTIONS.HOVER} //default
        hoverDelayInMs={250} //default: 0
        hoverOffDelayInMs={150} //default: 0
        onPositionChanged={(props) => {
          mouseFollow(props)
        }}>

    <Controller loglevel="0" refreshInterval="1">
      <Scene duration="100%" triggerHook="onLeave" >
        <Timeline>
            <div className={"cover__container d-flex flex-column d-flex justify-content-center align-items-center "+((props.enviOS)?("ios--container"):(" "))}>

                  <div className="d-flex justify-content-center align-items-center qanimation__container align-self-stretch">
                        <img src={q47} className="z-index-3 qanimation__image" alt="Queue" style={{opacity:1}} />
                  </div>

                  <div id="" className="coverimage__container mx-auto">
                    <img onLoad={(e)=>{imageLoadHandler(e)}} id="eyesBase" className="coverimage__image coverimage__base" src={eyesBase} alt="Queue"/>
                    <img  id="eyesDown" className="coverimage__image coverimage__overlay coverimage__look  " src={eyesDown} alt="Queue" />
                    <img  id="eyesClosed" className="coverimage__image coverimage__overlay coverimage__look  " src={eyesClosed} alt="Queue" />
                    <img  id="eyesLeft1" className="coverimage__image coverimage__overlay coverimage__look  " src={eyesLeft1} alt="Queue" />
                    <img  id="eyesLeft2" className="coverimage__image coverimage__overlay coverimage__look  " src={eyesLeft2} alt="Queue" />
                    <img  id="eyesLeft3" className="coverimage__image coverimage__overlay coverimage__look  " src={eyesLeft3} alt="Queue" />
                    <img  id="eyesRight1" className="coverimage__image coverimage__overlay coverimage__look  " src={eyesRight1} alt="Queue" />
                    <img  id="eyesRight2" className="coverimage__image coverimage__overlay coverimage__look  " src={eyesRight2} alt="Queue" />
                    <img  id="eyesRight3" className="coverimage__image coverimage__overlay coverimage__look  " src={eyesRight3} alt="Queue" />
                    <img  id="blink1" className="coverimage__image coverimage__overlay coverimage__blink" src={blink1} alt="Queue" />
                    <img  id="blink2" className="coverimage__image coverimage__overlay coverimage__blink" src={blink2} alt="Queue" />
                    <img  id="blink3" className="coverimage__image coverimage__overlay coverimage__blink" src={blink3} alt="Queue" />
                    <img  id="blink4" className="coverimage__image coverimage__overlay coverimage__blink" src={blink4} alt="Queue" />
                    <img  id="blink5" className="coverimage__image coverimage__overlay coverimage__blink" src={blink5} alt="Queue" />
                    <img  id="blink6" className="coverimage__image coverimage__overlay coverimage__blink" src={blink6} alt="Queue" />
                    <img  id="blink7" className="coverimage__image coverimage__overlay coverimage__blink" src={blink7} alt="Queue" />
                    <img  id="blink8" className="coverimage__image coverimage__overlay coverimage__blink" src={blink8} alt="Queue" />
                    <img  id="blink9" className="coverimage__image coverimage__overlay coverimage__blink" src={blink9} alt="Queue" />
                  </div>

                  <div className="coverscrollarrow__container d-flex justify-content-center align-items-center ">
                        <img src={scrollArrowDown} className="coverscrollarrow__image" alt="Queue" style={{opacity:1}} />
                  </div>


            </div>
        </Timeline>
      </Scene>
    </Controller>

            <div className="issueheadline__container d-flex justify-content-center align-items-center text-center" >
              <img src={comedyIssueHeadlineDesktop} className="issueheadline__image d-none d-md-block" alt="Queue"  />
              <img src={comedyIssueHeadlineMobile} className="issueheadline__image d-block d-md-none" alt="Queue"  />
            </div>


</ReactCursorPosition>
</div>


    )
  } else {
    return ("")
  }
}

export default TableOfContentsInner
