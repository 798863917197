import React from 'react'
// import $ from 'jquery'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'
// import PullQuote from '../PullQuote/PullQuote'

// import lettermanAsset2 from '../../_assets/img/test/letterman-2.jpg'

const MaskedMedia = (props) => {

    // const [ ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)), set((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)) ] = useState({})

    // useLayoutEffect( () => {
    //   let subscribed = true;
    //   if (subscribed) {
    //     // set((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop))((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop))
    //   }
    //   return ()=>{subscribed=false}
    // }, [props.isMobile, props.widgetObject])


if (Object.keys(((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop))).length) {

    return (

      <div className={(((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).full_width)?(""):("width_ctrl")}>


        <Controller loglevel="0" refreshInterval="1">
          <Scene pin duration="300%" triggerHook="onLeave" >
          <Timeline>

          <div className={"maskedmedia__container mb-5 "+((props.enviOS)?("ios--container"):(" "))} style={props.widgetObject.style}>

          <div className="assetwidget__imgcontainer d-flex justify-content-center align-items-center">

            <div className="mask__container d-flex">


            <div className="mask__container--inner d-flex flex-column ">
                <div className=" mask_filler " style={{backgroundColor:props.articleObject.master_bg_color}} />
                <div className="mask__overlay">
                  <img alt="Queue" src={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).overlay.source} className=""   />
                </div>
                <div className="mask_filler" style={{backgroundColor:props.articleObject.master_bg_color}} />
            </div>

                    <Timeline
                      target={
                        <div className="assetwidget__scalecontainer" style={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.style}>
                            <img alt="Queue" src={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.source} className={" "+((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.className}   />

                        </div>
                      }>
                      {((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).tweens.map((obj,i)=>{
                        return (
                          <Tween key={i} {...obj} />
                          )
                        })}
                    </Timeline>

              </div>



          </div>
          </div>
          </Timeline>
          </Scene>
        </Controller>

      </div>

    )

  } else {
      return ("")
  }

}

export default MaskedMedia
