import React from 'react'
// import $ from 'jquery'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'

// import testImage1 from '../../_assets/img/test/wanda-2.png'

const DropCap = (props) => {

    // console.log($('#root').height(), document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight)

    // const windowHeight = document.documentElement.clientHeight;

    // const storyLedeStyle = {
    //   backgroundColor:'#153353'
    // };

    const dropCapOutlinedStyle = {
      color:props.widgetObject.text_color,
      fontSize:(("font_size" in props.widgetObject)?(props.widgetObject.font_size):("")),
      WebkitTextStrokeWidth: ((props.widgetObject.outline)?("5px"):("0px")),
      // textShadow: '-1px -1px 0 #000, 1px -1px 0 #000,-1px 1px 0 #000, 1px 1px 0 #000'
      WebkitTextStrokeColor: ((props.widgetObject.outline)?(props.widgetObject.outline_color):("transparent")),
    };
    const dropCapStyle = {
      color:(("text_color" in props.widgetObject)?(props.widgetObject.text_color):('')),
      fontSize:(("font_size" in props.widgetObject)?(props.widgetObject.font_size):('')),
    };

    if (("stretch" in props.widgetObject) && props.widgetObject.stretch) {

    return (

      <div className="widget_dropcap">

      <Controller loglevel="0">
        <Scene duration="100%" triggerHook="onEnter" >
          <Timeline>

    <div className=" width_ctrl d-flex justify-content-start no-gutters">
      <div className=" col-12 col-md-8">
        <div className="dropcap__container text-center position-relative">
          <Timeline>
            <Tween to={{ transform:"scale(1,2)",transformOrigin:"bottom" }} delay={0} ease="none" overwrite={false}>
              <div className="dropcap__letter--wrapper">
                <div className="loading dropcap__letter " style={dropCapOutlinedStyle}>{props.widgetObject.letter}</div>
                <div className="loading  dropcap__letter--overlay " style={dropCapStyle}>{props.widgetObject.letter}</div>
                </div>
            </Tween>
          </Timeline>
        </div>

      </div>
    </div>

    </Timeline>
  </Scene>
</Controller>
</div>

    )

  } else {
    return (

    <div className="widget_dropcap width_ctrl d-flex justify-content-start no-gutters">
      <div className=" col-12 col-md-8">
        <div className="dropcap__container text-center position-relative">

              <div className="dropcap__letter--wrapper">
                <div className="dropcap__letter " style={dropCapOutlinedStyle}>{props.widgetObject.letter}</div>
                <div className=" dropcap__letter--overlay " style={dropCapStyle}>{props.widgetObject.letter}</div>
              </div>

        </div>

      </div>
    </div>

    )
  }
}

export default DropCap
