import React, {useLayoutEffect} from 'react'
// import {Row, Col, Accordion} from 'react-bootstrap'
// import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'
// import { useHistory } from "react-router-dom";
// import $ from 'jquery'

// import { useInView } from 'react-intersection-observer'

import WidgetContainer from '../../../Common/Widgets/WidgetContainer/WidgetContainer'

// import StoryOpener from '../../../Common/Widgets/StoryOpener/StoryOpener'
// import BodyCopy from '../../../Common/Widgets/BodyCopy/BodyCopy'
// import PullQuote from '../../../Common/Widgets/PullQuote/PullQuote'
// import AssetWidget from '../../../Common/Widgets/AssetWidget/AssetWidget'
// import StoryCredits from '../../../Common/Widgets/StoryCredits/StoryCredits'
// import DropCap from '../../../Common/Widgets/DropCap/DropCap'



const Article = (props) => {


  const moreContentButton = '/_assets/img/more-content.svg'

  // const [articleObject, setArticleObject] = useState({})
        // [articleWidgets, setArticleWidgets] = useState([])


    //
    // document.addEventListener('DOMContentLoaded', (event) => {
    //   //the event occurred
    //   const [ref, inView] = useInView({
    //     /* Optional options */
    //     threshold: 0,
    //   })
    // })


  useLayoutEffect( () => {
    let subscribed = true;
    if (subscribed) {
      // setArticleObject(props.articleObject)
      // console.log(props)
      // setArticleWidgets(props.articleObject.widgets)

      // // page load for each story
      // let pathLoaded = false
      //
      // if (window.location.pathname && !pathLoaded) {
      //   let windowPath = window.location.pathname.replace('/','')
      //   // console.log('path check', windowPath)
      //   if (windowPath === props.articleObject.slug) {
      //     // console.log('slug check true', windowPath, props.articleObject.slug)
      //     document.getElementById(windowPath).scrollIntoView()
      //     pathLoaded = true
      //   }
      // } else {
      //   window.location.href = ""
      // }

      // console.log(props.articleObject.slug, inView)



    }
    return ()=>{subscribed=false}
  }, [props.articleObject])

  if (props.articleObject) {

    return (
      <div className="storyfull__container--inner" style={{color:props.articleObject.master_text_color}}>



      {(props.articleObject)?(
          props.articleObject.widgets.map((obj,i)=>{
            if (obj.widget_type === "widget_storyopener") {
              return (
                <WidgetContainer key={i}
                    articleObject={props.articleObject}
                    widgetObject={obj}
                    widgetType={obj.widget_type}
                    isMobile={props.isMobile} isSafari={props.isSafari} enviOS={props.enviOS}  />
              )
            } else {
              return ""
            }
            })
      ):("")}


          <div className="storybody__container z-index-3 position-relative mt-3 mt-md-5">


          {(props.articleObject && props.articleObject.widgets)?(
              props.articleObject.widgets.map((obj,i)=>{
                  if ((obj.widget_type !== "widget_storyopener")&&(obj.widget_type !== "widget_endcopy")) {
                    return (
                      <WidgetContainer key={i}
                          articleObject={props.articleObject}
                          widgetObject={obj}
                          widgetType={obj.widget_type}
                          isMobile={props.isMobile} isSafari={props.isSafari} enviOS={props.enviOS}  />
                    )
                  } else {
                    return ""
                  }
                })
          ):("")}



          </div>

          {(props.articleObject.link_out)?(
          <div className="width_ctrl bodycopy__container morecontent__container">
          <div className="position-relative z-index-3">
            <div className="morecontent__inner col-12 col-md-4">

            {(props.articleObject.link_out)?(
                props.articleObject.link_out.map((obj,i)=>{

                      return (
                        <div key={i} className="mt-5">
                          <div className="morecontent__button">
                            <a href={obj.url} target="_blank" rel="noopener noreferrer"><img src={moreContentButton} className="morecontent__button--image" alt="Queue" /></a>
                          </div>
                          <div className="loading morecontent__label" dangerouslySetInnerHTML={{__html: obj.text}} />
                        </div>
                      )

                  })
            ):("")}


            </div>
          </div>
          </div>
          ):("")}


          {(props.articleObject)?(
              props.articleObject.widgets.map((obj,i)=>{
                if (obj.widget_type === "widget_endcopy") {
                  return (
                    <WidgetContainer key={i}
                        articleObject={props.articleObject}
                        widgetObject={obj}
                        widgetType={obj.widget_type}
                        isMobile={props.isMobile} isSafari={props.isSafari} />
                  )
                } else {
                  return ""
                }
                })
          ):("")}



      </div>
    )
  } else {
    return("")
  }

}

export default Article
