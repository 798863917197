import React, {useLayoutEffect} from 'react'
import $ from 'jquery'
// import { useHistory } from "react-router-dom";

import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'
// import Credits from './Credits'








const MissionStatement = (props) => {





  useLayoutEffect( () => {
    let subscribed = true;
    if (subscribed) {

      $(document).on('click', '.missionstatement__skipintro--wrapper', (e)=> {
        e.preventDefault()
        // console.log('clicked');
        document.getElementById("toc_initial").scrollIntoView({
          behavior: 'smooth'
        })
        setTimeout(function(){
          document.getElementById("toc_initial").scrollIntoView({
            behavior: 'smooth'
          })
        }, 500);
      })

    }
    return ()=>{subscribed=false}
  }, [ ])

    return (


<Controller loglevel="0" refreshInterval="1">
  <Scene duration="200%" pin pushFollowers="false" triggerHook="onLeave" >
    <Timeline>
        <div className="missionstatement__container d-flex flex-wrap flex-column flex-md-row pt-5 pt-md-0 justify-content-start justify-content-md-center align-items-center w-100 h-100">


          <div className="w-100 d-flex no-gutters pb-5 pb-md-0">
            <div className="col-12 col-md-8 missionstatement__text">
              <p><span className="loading"><em>Queue</em> is a place for the diverse Netflix family to share their stories. In this special comedy tribute issue, you’ll hear from the hardworking people — onscreen and off — whose insights and observations keep us laughing and lift our spirits. Our mission is to pay homage to the process of crafting great entertainment on Netflix and beyond. To the talented people who keep us smiling, we salute you.*</span></p>
            </div>
          </div>
          <div className="w-100 d-flex flex-column flex-md-row no-gutters missionstatement__footer--container">
            <div className="col-12 col-md-8 missionstatement__footer">
            <span className="loading ">*We tried to make this paragraph funny, but we failed. <br className="d-none d-md-block"/>So instead we present this issue of <em>Queue</em> in Comic Sans.</span>
            </div>
            <div className="mt-5 mt-md-0 col-12 col-md-4 missionstatement__skipintro--container d-flex justify-content-start justify-content-md-end align-self-end">
            <div className="missionstatement__skipintro--wrapper">

              <div className="loading missionstatement__skipintro">
              SKIP INTRO
              </div>

              <Timeline>
                <Tween to={{ width:"100%" }} delay="0" ease="none" overwrite={false}>
                <div className="missionstatement__skipintro-bg"/>
                </Tween>
              </Timeline>
              </div>
            </div>
          </div>

        </div>
</Timeline>

</Scene>
</Controller>


    )
}

export default MissionStatement
