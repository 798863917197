import React, {useLayoutEffect} from 'react'
import $ from 'jquery'
import { useHistory } from "react-router-dom";

// import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'
import Credits from './Credits'

import testObjects from '../../Common/TestContentObjects/NavObjects'

// import queueLogoWhite from '../../Common/_assets/img/logo-queue-white.svg'

const TableOfContentsInner = (props) => {


    const queueLogoRed = '/_assets/img/logo-queue-red.svg'
    const backarrow = '/_assets/img/backarrow.svg'

  // const [importedObjects, setImportedObjects] = useState([])
  // console.log(testObjects)
  const history = useHistory()

  // $(document).on('click', '.tocnav__link', (e)=>{
  //   e.preventDefault()
  //   .scrollIntoView()
  // })





  useLayoutEffect( () => {
    let subscribed = true;
    if (subscribed) {
      // setImportedObjects(testObjects.testObjects)

      $(document).on('click', '.toc__credits--show', (e)=> {
        e.preventDefault()
        // console.log('clicked');
        $('.credits__container').addClass('active')
      })

      $(document).on('touch click', '.tocnav__link--flyout', (e) => {
          e.preventDefault()
          let slug = $(e.target).attr('data-slug')
          console.log('tapped',slug)
          history.push(slug)
          document.getElementById(slug).scrollIntoView()
          setTimeout(()=>{
            $('.tocflyoutnav__container').removeClass('active')
          }, 1000);
      });


    }
    return ()=>{subscribed=false}
  }, [history])

    return (


      <div className="tocflyoutnav__container">
          <div className="tocnav__inner d-flex flex-column h-100">
            <div className="tocnav__header d-flex align-items-center">
              <div className="toc__disable tocnav__logo d-flex align-self-stretch">
                <img src={queueLogoRed} className="tocnav__logo--image" alt="Queue" /><img src={backarrow} className="tocnav__backarrow ml-3" alt="Queue" />
              </div>
              <div className="ml-auto tocnav__title font-bold">
                <a href="/"><span className="loading "><span className="text-red">QUEUE</span>&nbsp;&nbsp;The Comedy Issue</span></a>
              </div>
            </div>
            <div className="tocnav__list position-relative flex-fill">

              <div className="tocnav__scroll">
                <ul className="">
                {
                  (testObjects.testObjects)?(
                  testObjects.testObjects.map((obj,i)=>{
                    return (
                      <li key={i} className="tocnav__item d-flex flex-column flex-md-row">
                      <div className="tocnav__item--inner">
                      <a className="loading tocnav__link tocnav__link--flyout tocnav__item--text" href={"/"+obj.slug} data-slug={obj.slug} dangerouslySetInnerHTML={{__html: obj.title}} />
                        <div className="loading tocnav__item--subtitle align-self-start align-self-md-end  mt-2 mt-md-0">{obj.subtitle}</div>
                        </div>
                      </li>
                    )
                  })
                ):("")
                }
                </ul>
            </div>
            </div>
            <Credits isMobile={props.isMobile} />
            <div className="creditslink">
            <a className="loading toc__credits--show" href="/#">Credits</a>  
            </div>
          </div>
      </div>


    )
}

export default TableOfContentsInner
