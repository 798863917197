import React from 'react'
// import $ from 'jquery'
// import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'

// import testImage1 from '../../_assets/img/test/wanda-2.png'

const EndCopy = (props) => {

    // console.log($('#root').height(), document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight)

    // const windowHeight = document.documentElement.clientHeight;

    const bodyCopyStyle = {
      color:props.widgetObject.text_color
    };



      return (
        <div className="pt-5 width_ctrl bodycopy__container d-flex justify-content-start no-gutters">
          <div className={"bodycopy__text col-12 col-md-8 "+((("className" in props.widgetObject))?(props.widgetObject.className):(""))} style={((("style" in props.widgetObject))?(props.widgetObject.style):(bodyCopyStyle))}>
            <div dangerouslySetInnerHTML={{__html: props.widgetObject.content}} />
          </div>
        </div>
      )



}

export default EndCopy
