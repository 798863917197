import React, {useLayoutEffect} from 'react'
import $ from 'jquery'
import { useHistory } from "react-router-dom";

import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'
// import CreditsInitial from './CreditsInitial'

import testObjects from '../../Common/TestContentObjects/NavObjects'

// import queueLogoWhite from '../../Common/_assets/img/logo-queue-white.svg'

const TableOfContentsInitial = (props) => {


  const queueLogoRed = '/_assets/img/logo-queue-red.svg'

  // const [importedObjects, setImportedObjects] = useState([])
  // console.log(testObjects)
  const history = useHistory()

  // $(document).on('click', '.tocnav__link', (e)=>{
  //   e.preventDefault()
  //   .scrollIntoView()
  // })

  // const navAction = (val) => {
  //   history.push(val)
  //   setTimeout(function(){
  //     document.getElementById(val).scrollIntoView()
  //   }, 500);
  // }



  $(document).on('touchstart click', '.tocnav__link', (e) => {
      e.preventDefault()
      let slug = $(e.target).attr('data-slug')
      console.log('tapped',slug)
      history.push(slug)
      setTimeout(()=>{
        document.getElementById(slug).scrollIntoView()
      }, 1000);
  });

  // const tocNavAction = (val) => {
  //   history.push(val)
  //   document.getElementById(val).scrollIntoView()
  //   setTimeout(function(){
  //     $('.tocflyoutnav__container').removeClass('active')
  //   }, 500);
  // }

  $(document).on('click', '.toc__credits--show', (e)=> {
    e.preventDefault()
    // console.log('clicked');
    $('.credits__container').addClass('active')
  })

  useLayoutEffect( () => {
    let subscribed = true;
    if (subscribed) {
      // setImportedObjects(testObjects.testObjects)

    }
    return ()=>{subscribed=false}
  }, [])



    return (



      <div id="toc_initial" className="tocnav__detector tocnav__container">






          <div className="tocnav__inner d-flex flex-column">

          <Controller loglevel="0" refreshInterval="1">
            <Scene duration={(props.isMobile)?("1380px"):("2460px")} pin pushFollowers="false" triggerHook="onLeave" >
            <div className="tocnav__header d-flex align-items-center">
              <div className="tocnav__logo d-flex align-self-stretch d-flex align-items-center pointer-all">
                <a href="/" className="pointer-all"><img src={queueLogoRed} className="tocnav__logo--image pointer-all" alt="Queue" /></a>
              </div>
              <div className="ml-auto tocnav__title font-bold">
                <a href="/" className="pointer-all"><span className="loading "><span className="text-red loading">QUEUE</span>&nbsp;&nbsp;The Comedy Issue</span></a>
              </div>
            </div>
            </Scene>
          </Controller>


            <div className="tocnav__list  flex-fill">


              <div className="tocnav__scroll">
                <ul className="">
                {
                  (testObjects.testObjects)?(
                  testObjects.testObjects.map((obj,i)=>{
                    return (
                      <li key={i} className="tocnav__item d-flex flex-column flex-md-row">
                        <div className="tocnav__item--inner">
                        <a className="loading tocnav__link  tocnav__item--text" href={"/"+obj.slug} data-slug={obj.slug} onClick={(e)=>{e.preventDefault()}} dangerouslySetInnerHTML={{__html: obj.title}} />
                          <div className="loading tocnav__item--subtitle  align-self-start align-self-md-end mt-2 mt-md-0">{obj.subtitle}</div>
                        </div>
                      </li>
                    )
                  })
                ):("")
                }
                </ul>
            </div>




            </div>


            <div className="initialcreditslink creditslink">
            <a className="loading toc__credits--show" href="/#">Credits</a>  
            </div>

          </div>


</div>




    )
}

export default TableOfContentsInitial
