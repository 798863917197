import React from 'react'
// import $ from 'jquery'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'

// import testImage1 from '../../_assets/img/test/wanda-2.png'

const DropCapImage = (props) => {



    if (("stretch" in props.widgetObject) && props.widgetObject.stretch && !props.isMobile) {

    return (

      <div className="widget_dropcap">

      <Controller loglevel="0">
        <Scene duration="100%" triggerHook="onEnter" >
          <Timeline>

    <div className=" width_ctrl d-flex justify-content-start no-gutters">
      <div className=" col-12 col-md-8">
        <div className="dropcap__container text-center position-relative">
          <Timeline>
            <Tween to={{ transform:"scale(1,2)",transformOrigin:"bottom" }} delay={0} ease="none" overwrite={false}>
              <div className="dropcap__letter--wrapper px-3">
                <img alt="Queue" src={props.widgetObject.source} className="w-50 dropcapimage"/>
                </div>
            </Tween>
          </Timeline>
        </div>

      </div>
    </div>

    </Timeline>
  </Scene>
</Controller>
</div>

    )

  } else if (("stretch" in props.widgetObject) && props.widgetObject.stretch && props.isMobile) {
    return (

      <div className="widget_dropcap">



    <div className=" width_ctrl d-flex justify-content-start no-gutters">
      <div className=" col-12 col-md-8">
        <div className="dropcap__container text-center position-relative">

              <div className="dropcap__letter--wrapper px-3">
                <img alt="Queue" src={props.widgetObject.source} className="w-50 dropcapimage"/>
                </div>

        </div>

      </div>
    </div>

  </div>

    )
  } else {
    return ("")
  }
}

export default DropCapImage
