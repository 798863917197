import React, {useLayoutEffect} from 'react'
// import {Row, Col, Accordion} from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import $ from 'jquery'
// import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'

import {testObjects} from '../../Common/TestContentObjects/Articles'


import Article from './Article/Article'
import StoryFooter from '../../Common/Widgets/StoryFooter/StoryFooter'
import TableOfContentsInitial from '../TableOfContents/TableOfContentsInitial'
import TableOfContentsEnd from '../TableOfContents/TableOfContentsEnd'
// import TableOfContentsInnerFlyout from '../TableOfContents/TableOfContentsInnerFlyout'
import ComedyIssueCover from '../Covers/ComedyIssueCover'
import MissionStatement from '../Covers/MissionStatement'
// import StoryFooter from '../../../Common/Widgets/StoryFooter/StoryFooter'


// import letterman1 from '../../Common/_assets/img/test/letterman-1.jpg'
// import lettermanQuoteImageOpen from '../../Common/_assets/img/test/letterman-quote-open.svg'
// import lettermanQuoteImageClose from '../../Common/_assets/img/test/letterman-quote-close.svg'

// import wanda1 from '../../Common/_assets/img/test/wandasykes-1.jpg'
// import wandaQuoteImageOpen from '../../Common/_assets/img/test/w_quote.gif'


    // const loadImage = '/_assets/img/test/Q_Load_Low.gif'
    // const bg = process.env.PUBLIC_URL+'/_assets/img/test/coverbg.jpg'


      const queueLogoRed = process.env.PUBLIC_URL+'/_assets/img/logo-queue-red.svg'
      const queueLogoWhite = process.env.PUBLIC_URL+'/_assets/img/logo-queue-white.svg'


const ArticleList = (props) => {


    const history = useHistory()
    // const match = useRouteMatch();
    // const [importedObjects, setImportedObjects] = useState([])
          // [storiesInDom, setStoriesInDom] = useState(null)

    // const controllerRef = useRef()


    // const checkScrolledAwayTop = (el) => {
    //   let elementTop = $(el).offset().top;
    //   let viewportTop = $(window).scrollTop();
    //   let elementBottom = elementTop + $(el).outerHeight();
    //   // let viewportBottom = viewportTop + $(window).height();
    //   // return elementBottom > viewportTop && elementTop < viewportBottom;
    //   return elementBottom < viewportTop;
    // }
    // const checkScrolledAwayBottom = (el) => {
    //   let elementTop = $(el).offset().top;
    //   let viewportTop = $(window).scrollTop();
    //   // let elementBottom = elementTop + $(el).outerHeight();
    //   let viewportBottom = viewportTop + $(window).height();
    //   // return elementBottom > viewportTop && elementTop < viewportBottom;
    //   return elementTop > viewportBottom;
    // }
    // const checkBottomIntoView = (el) => {
    //   let elementTop = $(el).offset().top;
    //   let viewportTop = $(window).scrollTop();
    //   let elementBottom = elementTop + $(el).outerHeight();
    //   let viewportBottom = viewportTop + $(window).height();
    //   return elementBottom < viewportBottom && elementTop < viewportTop;
    //   // return elementTop > viewportBottom;
    // }

    // const clickMe = () => {
    //   document.getElementById("wanda-sykes").scrollIntoView();
    // }





    useLayoutEffect(() => {
      let subscribed=true
      if (subscribed) {




        const loadHandler = () => {
          // console.log('handler fired');

          // setTimeout(function(){
            document.fonts.ready.then( () => {
              // console.log('All fonts in use by visible text have loaded.', document.fonts);
               // console.log('Comic Sans loaded? ' + document.fonts.check('Comic Sans W01 Regular'));  // true
               $('.loading').removeClass('loading')
            })
            document.fonts.onloadingdone = (fontFaceSetEvent) => {
               // console.log('font loading done',fontFaceSetEvent);
            }
          // }, 500);
        }

        document.onreadystatechange = () => {
          if (document.readyState === "complete") {
            loadHandler();
          }
        }

        window.onload = () => {

          // console.log('window load')

          const els = document.querySelectorAll('.storyfull__container');
          const winheight = window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight;


          let observer = new IntersectionObserver((entries, observer) => {
            // console.log('entry:', entry);
            // console.log('observer:', observer);
            entries.forEach(entry => {
              // console.log('entry:', entry.target.id, entry.intersectionRatio, entry);
              // if (((entry.boundingClientRect.top>0)&&(entry.boundingClientRect.top<100))||((entry.boundingClientRect.bottom>(0))&&(entry.boundingClientRect.top<100))) {
              if (
                (((entry.boundingClientRect.top>0)&&(entry.boundingClientRect.top<100))
                ||((entry.boundingClientRect.bottom>(winheight-100))&&(entry.boundingClientRect.top<winheight)))
                ) {
                // if (entry.intersectionRatio > 0) {
                  // console.log('entry:', entry.target.id, entry.intersectionRatio, entry);
                  history.push(entry.target.id)
                // }
              }

              // console.log('observer:', observer);
            });
          }, {
              root: document.getElementById('superWrapper'),
              rootMargin: '0px',
              threshold: [0],
              trackVisibility: true,
              delay: 100
          });

          els.forEach(el => observer.observe(el));



          let coverObserver = new IntersectionObserver((entries, observer) => {
            // console.log('entry:', entry);
            // console.log('observer:', observer);
            entries.forEach(entry => {
              // console.log('COVER:', entry.target.id, entry.intersectionRatio, entry);
              // if (((entry.boundingClientRect.top>0)&&(entry.boundingClientRect.top<100))||((entry.boundingClientRect.bottom>(0))&&(entry.boundingClientRect.top<100))) {
              // if (((entry.boundingClientRect.top>0)&&(entry.boundingClientRect.top<100))||((entry.boundingClientRect.bottom>(winheight-100))&&(entry.boundingClientRect.top<winheight))) {
              //   console.log('hit cover')
              // }
              if (!entry.isIntersecting) {
                document.getElementById('toclogo').style.opacity = 1;
              } else {
                document.getElementById('toclogo').style.opacity = 0;
              }

              // console.log('observer:', observer);
            });
          }, {
              root: document.getElementById('superWrapper'),
              rootMargin: '0px',
              threshold: [0],
              trackVisibility: true,
              delay: 100
          });

          coverObserver.observe(document.querySelector('.covermissiontoc__container'));



          const tocs = document.querySelectorAll('.tocnav__detector');
          let tocObserver = new IntersectionObserver((entries, observer) => {
            // console.log('entry:', entry);
            // console.log('observer:', observer);
            entries.forEach(entry => {
              // console.log('COVER:', entry.target.id, entry.intersectionRatio, entry);
              // if (((entry.boundingClientRect.top>0)&&(entry.boundingClientRect.top<100))||((entry.boundingClientRect.bottom>(0))&&(entry.boundingClientRect.top<100))) {
              // if (((entry.boundingClientRect.top>0)&&(entry.boundingClientRect.top<100))||((entry.boundingClientRect.bottom>(winheight-100))&&(entry.boundingClientRect.top<winheight))) {
              //   console.log('hit cover')
              // }
              if (entry.isIntersecting) {
                // console.log('toc')
                document.querySelectorAll('.initialcreditslink').forEach(link => {
                  link.style.opacity = 1;
                });

              } else {
                document.querySelectorAll('.initialcreditslink').forEach(link => {
                  link.style.opacity = 0;
                });
              }

              // console.log('observer:', observer);
            });
          }, {
              root: document.getElementById('superWrapper'),
              rootMargin: '0px',
              threshold: [0],
              trackVisibility: true,
              delay: 100
          });

          tocs.forEach(toc => tocObserver.observe(toc));


        }


        // history.push("myslug")

        // if (testObjects.length) {
        //   // setImportedObjects(testObjects)
        // }
          // console.log('testObjects', testObjects, testObjects.length)

        // let curPos = window.pageYOffset
        // window.onscroll = () => {
        //     // console.log('scrolling')
        //     // let fast = (Math.abs(window.pageYOffset-curPos) > 150)?(true):(false)
        //     // if (!fast) {
        //       document.querySelectorAll('.storyfull__container').forEach.call(document.querySelectorAll('.storyfull__container'), function(story) {
        //         let bounding = story.getBoundingClientRect()
        //         let inview = (((bounding.top > 0 && ((bounding.top < (window.innerHeight)))) || (bounding.top < 0 && ((bounding.bottom > (window.innerHeight)))))?(true):(false))
        //         // console.log(story.id, inview)
        //         if (inview && (window.location.pathname !== ("/"+story.id))) {
        //           // console.log(window.location.pathname, story.id)
        //           history.push(story.id)
        //         }
        //       });
        //     // }
        //     // curPos = window.pageYOffset
        // }





      }
    }, [history])


    if ((testObjects.length === 19) && testObjects) {

    return (
          <div id="superWrapper" className={"scrollContainer "+((props.isSafari)?("safari--container"):(""))}>







          <div className="covermissiontoc__container">

          <ComedyIssueCover isMobile={props.isMobile} isSafari={props.isSafari} enviOS={props.enviOS} />
          <MissionStatement isMobile={props.isMobile} isSafari={props.isSafari} />
          <TableOfContentsInitial isMobile={props.isMobile} isSafari={props.isSafari} />
          </div>


            {
              (Object.keys(testObjects).length)?(
              testObjects.map((obj,i)=>{
                // console.log('obj',obj)
                let allObjects = Object.keys(testObjects).length;
                return (
                  <div key={i} className="storyfull__container" id={obj.slug} data-article-slug={obj.slug} style={{backgroundColor:obj.master_bg_color}}>
                      <Article isMobile={props.isMobile} isSafari={props.isSafari} enviOS={props.enviOS} 
                                articleBgColor={obj.master_bg_color}
                                articleTextColor={obj.master_text_color}
                                ledeBgColor={obj.theme_color}
                                quoteImageOpen={obj.quote_open}
                                quoteImageClose={obj.quote_close}
                                pullQuoteTextColor={obj.theme_color}
                                dropCapColor={obj.theme_color}
                                storyOpenerBgAsset={obj.opener_bg}
                                articleSlug={obj.slug}
                                articleObject={obj} />
                      {(i < (allObjects-1))?(
                        <StoryFooter isMobile={props.isMobile} isSafari={props.isSafari}
                                      upNextName={testObjects[i+1].nav_title}
                                      upNextSlug={testObjects[i+1].slug} />
                      ):("")}

                  </div>
                )
              })
            ):("")
            }

            <TableOfContentsEnd isMobile={props.isMobile} isSafari={props.isSafari} />

            <div id="toclogo" className="toc__logo--container d-flex align-items-center ">
              <div className="toc__logo--inner d-flex align-self-stretch ">
                <div className="toc__logo--mouseover d-flex align-self-stretch">
                  <div className="toc__logo--mouseover--logo d-flex align-self-stretch align-items-center">
                    <img src={queueLogoWhite} className="toc__logo--image toc__logo--white" alt="Queue" />
                    <img src={queueLogoRed} className="toc__logo--image toc__logo--red" alt="Queue" />
                  </div>
                  <div className="toc_logo_mouseover--label pl-3 d-none d-sm-flex align-items-center align-self-stretch">
                    Contents
                  </div>
                </div>
              </div>
            </div>

        </div>






    )
  } else {
    return("")
  }
}

export default ArticleList
