import React, { createContext } from 'react'
import { get, post, put, del, upload } from './Api'
import { slugify } from './Data'
import { feedback } from './Feedback'
import { confirm } from './Dialog'

export const AppContext = createContext({})

const AppProvider = (props) => {
    return (
        <AppContext.Provider value={
            {
                get: get,
                post: post,
                put: put,
                del: del,
                upload: upload,
                slugify: slugify,
                feedback: feedback,
                confirm: confirm
            }
        }>
            { props.children }
        </AppContext.Provider>
    )
}

export default AppProvider