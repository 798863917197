import React from 'react'
// import $ from 'jquery'
// import { Controller, Scene } from 'react-scrollmagic'
// import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'
// import PullQuote from '../PullQuote/PullQuote'

// import lettermanAsset2 from '../../_assets/img/test/letterman-2.jpg'

const VideMedia = (props) => {

    // const assetImageDesktopStyle = {
    //   height:'100vh',
    //   backgroundImage: 'url(' + props.widgetObject.bg_media_dt_s3 + ')',
    //   backgroundPosition:'center',
    //   backgroundRepeat:'no-repeat',
    //   backgroundSize:'cover'
    // };
    // const assetImageMobileStyle = {
    //   height:'100vh',
    //   backgroundImage: 'url(' + props.widgetObject.bg_media_mb_s3 + ')',
    //   backgroundPosition:'center',
    //   backgroundRepeat:'no-repeat',
    //   backgroundSize:'cover'
    // };

    return (
      <div className={(props.widgetObject.full_width)?(""):("width_ctrl staticassetwidget__padding")}>
      <div className="staticassetwidget__container mb-5">

        <div className="staticassetwidget__imgcontainer">

          <div id="" className={"text-"+props.widgetObject.position} style={((props.isMobile)?({width:"100%",height:'auto'}):({width:props.widgetObject.width,height:'auto'}))}>

            <video autoPlay loop muted playsInline className="videoasset__element">
                <source src={props.widgetObject.video_media_dt_s3} type="video/mp4" />
                Your browser doesn't support embedded videos.
            </video>
          </div>

          {(props.widgetObject.credit)?(
            <div id="" className="staticassetwidget__credit font-bold mt-2">
              {props.widgetObject.credit}
            </div>
          ):("")}
          {(props.widgetObject.caption)?(
            <div id="" className="staticassetwidget__caption font-bold mt-2">
              {props.widgetObject.caption}
            </div>
          ):("")}



        </div>

      </div>
      </div>

    )
}

export default VideMedia
