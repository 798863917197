export default {
  testObjects: [
    {
      title:'Taylor Tomlinson',
      subtitle:'Escapes a Cake',
      slug:"taylor-tomlinson"
    },
    {
      title:'Bert Kreischer',
      subtitle:'Is a Big Boy',
        slug:"bert-kreischer"
    },
    {
      title:'Tom Segura',
      subtitle:'Doesn’t Care How You’re Doing',
      slug:"tom-segura"
    },
    {
      title:'Wanda Sykes',
      subtitle:'Does It All',
      slug:"wanda-sykes"
    },
    {
      title:'Routine',
      subtitle:'With Iliza Shlesinger, Tom Papa, Lauren Lapkus, and Fortune Feimster',
slug:"routine"
    },
    {
      title:'David Letterman',
      subtitle:'Sprays His Beard With Herbicide',
      slug:"david-letterman"
    },
    {
      title:'Ellen DeGeneres',
      subtitle:'Is Just Like You and Me',
slug:"ellen-degeneres"
    },
    {
      title:'BoJack Horseman',
      subtitle:"Speaks Straight From the Mouth",
      slug:"bojack-horseman"
    },
    {
      title:'Dave Chappelle',
      subtitle:'Has Big Energy',
slug:"dave-chappelle"
    },
    {
      title:'Jerry Seinfeld<br>& Chris Rock',
      subtitle:'Are Best F*cking Friends',
slug:"jerry-seinfeld-chris-rock"
    },
    {
      title:'Vir Das',
      subtitle:'Splits His Pants',
      slug:"vir-das"
    },
    {
      title:'Park Na-rae',
      subtitle:'Has a Dirty Laugh',
slug:"park-na-rae"
    },
    {
      title:'Franco Escamilla',
      subtitle:'Falls Off Stage',
slug:"franco-escamilla"
    },
    {
      title:'Fary',
      subtitle:'Pretends to Read',
slug:"fary"
    },
    {
      title:'Big Mouth',
      subtitle:'Loves Florida',
slug:"big-mouth"
    },
    {
      title:'Hannah Gadsby',
      subtitle:"Remembers When . . .",
      slug:"hannah-gadsby"
    },
    {
      title:'Adam Sandler',
      subtitle:"Is Everywhere",
      slug:"adam-sandler"
    },
    {
      title:'Ronny Chieng',
      subtitle:'Is Not Amused',
      slug:"ronny-chieng"
    },
    {
      title:'Your Future',
      subtitle:'Is in the Cards',
slug:"your-future"
    }
  ]
}
