import React from 'react'
// import $ from 'jquery'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'

// import testImage1 from '../../_assets/img/test/wanda-2.png'
// import queueLogoRed from '../../../Common/_assets/img/logo-queue-red.svg'


const StoryFooter = (props) => {

    const queueLogoWhite = '/_assets/img/logo-queue-white.svg'

    // const storyFooterDesktopStyle = {
    //   backgroundColor:"#ccc"
    // };
    // const storyFooterMobileStyle = {
    //   backgroundColor:"#ccc"
    // };

    return (

      <div className="storyfooter__container" id={props.upNextSlug}>

    <Controller loglevel="0" refreshInterval="1">
      <Scene duration={"100%"} triggerHook="1" >
      <Timeline>

          <div className="storyfooter__inner w-100">

            <div id="" className="mark__end storyfooter__innercontainer d-flex align-items-center" >

              <div className="toc__trigger storyfooter__logo-container d-flex align-items-center align-self-stretch">
                <div className="storyfooter__logo d-flex align-items-center">
                  <img src={queueLogoWhite} className="storyfooter__logo--image" alt="Queue" />
                </div>
                <div className="loading storyfooter__logo-label storyfooter__logo-label-text pl-3 d-none d-sm-flex align-items-center align-self-stretch">
                  Contents
                </div>
              </div>

              <div className="storyfooter__progressbar--container pl-4 d-flex align-items-center flex-fill align-self-stretch">

                <div className="loading storyfooter__progressbar--label">
                  Up Next:
                </div>
                <div className="loading storyfooter__progressbar--label font-bold pl-1 pl-sm-3 ">
                  {props.upNextName}
                </div>



                    <Tween
                      to={{
                        width: '100%'
                      }} >
                        <div className="storyfooter__progressbar" />

                    </Tween>


              </div>
            </div>
          </div>

        </Timeline>
      </Scene>

      </Controller>

      </div>

    )
}

export default StoryFooter
