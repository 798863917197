import React from 'react'
// import $ from 'jquery'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

// import {Row, Col, Accordion} from 'react-bootstrap'
// import PullQuote from '../PullQuote/PullQuote'

// import lettermanAsset2 from '../../_assets/img/test/letterman-2.jpg'

const RichMedia = (props) => {

    // const [ ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)), set((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)) ] = useState({})

    // useLayoutEffect( () => {
    //   let subscribed = true;
    //   if (subscribed) {
    //     // set((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop))((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop))
    //   }
    //   return ()=>{subscribed=false}
    // }, [props])

if (((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)) && Object.keys(((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop))).length) {

if ("tweens" in ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop))) {

    return (

      <div className={(((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).full_width)?(""):("width_ctrl")}>
        <div className="assetwidget__container">

        <Controller loglevel="0" refreshInterval="1">
          <Scene pin duration="100%" triggerHook="onLeave" pushFollowers="false">
          <Timeline>

          <div className="assetwidget__imgcontainer assetwidget__scaleouter">

                    <Timeline
                      target={
                        <div className="assetwidget__scalecontainer" style={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.style}>
                            <img alt="Queue" src={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.source} className={" "+((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.className}   />

                        </div>
                      }>
                      {((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).tweens.map((obj,i)=>{
                        return (
                          <Tween key={i} {...obj} />
                          )
                        })}
                    </Timeline>

            {(("credit" in props.widgetObject))?(
              <div id="" className="loading staticassetwidget__credit font-bold mt-3">
                {props.widgetObject.credit}
              </div>
            ):("")}
            {(("caption" in props.widgetObject))?(
              <div id="" className="loading staticassetwidget__caption font-bold mt-3">
                {props.widgetObject.caption}
              </div>
            ):("")}

          </div>
          </Timeline>
          </Scene>
        </Controller>
        </div>
      </div>

    )

  } else if (!("youtube" in ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)))) {
      return (


      <div className={(((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).full_width)?(""):("width_ctrl")}>
        <div className="assetwidget__container">

          <div className="assetwidget__imgcontainer">

          {(((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).center_over_copy && ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image && !("video" in ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop))))?(
            <div className={"assetwidget__innercontainer  "+ ((props.widgetObject.caption)?("centered"):(""))}>
            <div className="assetwidget__scalecontainer" style={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.style}>
                <img alt="Queue" src={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.source} className={" "+((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.className}   />
                {(("credit" in props.widgetObject))?(
                  <div className={"loading staticassetwidget__credit font-bold mt-3 "} dangerouslySetInnerHTML={{__html: props.widgetObject.credit}} />
                ):("")}
                {(("caption" in props.widgetObject))?(
                  <div className={"loading staticassetwidget__caption font-bold mt-3 "} dangerouslySetInnerHTML={{__html: props.widgetObject.caption}} />
                ):("")}
            </div>
            </div>
          ):("")}


          {(!((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).center_over_copy && ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image && !("video" in ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop))))?(
            <div className="assetwidget__innercontainer d-flex flex-wrap no-gutters">

            {(((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).overlay_image)?(
              <img alt="Queue" src={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).overlay_image.source} style={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).overlay_image.style} className={" "+((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).overlay_image.className}   />
            ):("")}

            <img alt="Queue" src={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.source} style={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.style} className={" "+((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image.className}   />

            {(("credit" in props.widgetObject))?(
              <div className={"loading staticassetwidget__credit font-bold mt-3 "+((((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).full_width)?("full_width__caption--container"):(""))} dangerouslySetInnerHTML={{__html: props.widgetObject.credit}} />
            ):("")}

            {(("caption" in props.widgetObject))?(
              <div className={"loading staticassetwidget__caption font-bold mt-3 "+((((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).full_width)?("full_width__caption--container"):(""))} dangerouslySetInnerHTML={{__html: props.widgetObject.caption}} />
            ):("")}

            </div>

          ):("")}


          {(((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).center_over_copy && !((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image && (("video" in ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)))))?(
            <div className={"assetwidget__innercontainer  "+ ((props.widgetObject.caption)?("centered"):(""))}>
            <div className="assetwidget__scalecontainer col-12 col-sm-8">
            <video autoPlay loop muted playsInline className="videoasset__element-contained videoasset__element">
                <source src={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).video.source} type="video/mp4" />
                Your browser doesn't support embedded videos.
            </video>
            {(("credit" in props.widgetObject))?(
              <div className="loading staticassetwidget__credit font-bold mt-3" dangerouslySetInnerHTML={{__html: props.widgetObject.credit}} />
            ):("")}
            {(("caption" in props.widgetObject))?(
              <div className="loading staticassetwidget__caption font-bold mt-3" dangerouslySetInnerHTML={{__html: props.widgetObject.caption}} />
            ):("")}
            </div>
            </div>
          ):("")}


          {(!((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).center_over_copy && !((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).image && (("video" in ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)))))?(
            <div className="assetwidget__innercontainer">
            <video autoPlay loop muted playsInline className="videoasset__element">
                <source src={((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).video.source} type="video/mp4" />
                Your browser doesn't support embedded videos.
            </video>
            {(("credit" in props.widgetObject))?(
              <div className={"loading staticassetwidget__credit font-bold mt-3 "+((((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).full_width)?("full_width__caption--container"):(""))} dangerouslySetInnerHTML={{__html: props.widgetObject.credit}} />
            ):("")}
            {(("caption" in props.widgetObject))?(
              <div className={"loading staticassetwidget__caption font-bold mt-3 "+((((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).full_width)?("full_width__caption--container"):(""))} dangerouslySetInnerHTML={{__html: props.widgetObject.caption}} />
            ):("")}
            </div>
          ):("")}











          </div>
        </div>
      </div>


      )
  } else if (("youtube" in ((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)))) {
    return (

      <div className={(((props.isMobile)?(props.widgetObject.mobile):(props.widgetObject.desktop)).full_width)?(""):("width_ctrl")}>
        <div className="assetwidget__container">

          <div className="assetwidget__imgcontainer">

            <div className={"assetwidget__innercontainer  "+ ((props.widgetObject.caption)?("centered"):(""))}>
            <div className="video_container">

                <iframe title="Queue" width="560" height="315" src="https://www.youtube.com/embed/HeGbDRbodNQ?controls=0&playsinline=1&modestbranding=1&rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

            </div>
            </div>


          </div>
        </div>
      </div>

    )
  } else {
    return ("")
  }

} else {
  return ("")
}

}

export default RichMedia
