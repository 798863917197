import React from 'react'
import {Alert, Col, Row} from "react-bootstrap";

export const feedback = (status, isError, onOpen, onClose) => {
    let type
    isError ? type='danger' : type='success'
    onOpen (
        <Row>
            <Col span={ 24 }>
                <Alert variant={ type }
                       onClose={() => onClose() }
                       dismissible
                >{ status }</Alert>
            </Col>
        </Row>
    )
}