import React from 'react'
import {Button, Modal} from "react-bootstrap";

export const confirm = (title, message, open, close, accept) => {
    open(<Modal.Dialog>
        <Modal.Header closeButton>
            <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <p>{ message }</p>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={ () => close() }>Cancel</Button>
            <Button variant="primary" onClick={ () => {
                accept()
            }}>Accept</Button>
        </Modal.Footer>
    </Modal.Dialog>)
}